import React, { useState, useMemo, ChangeEvent, useEffect } from 'react'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridColDef,
  GridRowsProp,
  esES,
} from '@mui/x-data-grid'
import { TextField, Box, IconButton, Tooltip } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

interface DataGridWithSearchAndExportProps {
  columns: GridColDef[]
  typeReport: string
  rows: GridRowsProp
}

const DataGridWithSearchAndExport: React.FC<DataGridWithSearchAndExportProps> = ({
  columns,
  typeReport,
  rows,
}) => {
  const [searchText, setSearchText] = useState<string>('')
  const [filteredRows, setFilteredRows] = useState<GridRowsProp>(rows)

  // Manejo de la búsqueda
  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase()
    setSearchText(value)

    const filtered = rows.filter((row) =>
      Object.values(row).some((field) => field && field.toString().toLowerCase().includes(value)),
    )
    setFilteredRows(filtered)
  }

  // Función para copiar al portapapeles
  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value).then(
      () => {
        alert(`Copiado: ${value}`)
      },
      () => {
        alert('Error al copiar al portapapeles')
      },
    )
  }

  useEffect(() => {
    setFilteredRows(rows)
  }, [rows])

  const enhancedColumns = useMemo(() => {
    return columns.map((col) => {
      if (
        col.field === 'creditId' ||
        col.field === 'lendusId' ||
        col.field === 'depositId' ||
        col.field === 'conciliationId' ||
        col.field === 'id' ||
        col.field === 'referenceNumber'
      ) {
        return {
          ...col,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          renderCell: (params: any) => (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <span>{col.field === 'referenceNumber' && params.value}</span>
              <Tooltip title={params.value}>
                <IconButton onClick={() => copyToClipboard(params.value)} size="small">
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          ),
        }
      }
      return col
    })
  }, [columns])

  return (
    <Box sx={{ height: 700, width: '100%' }}>
      {/* Buscador */}
      <TextField
        label="Buscar"
        variant="outlined"
        value={searchText}
        onChange={handleSearch}
        sx={{ marginBottom: 2 }}
      />

      {/* DataGrid */}
      <DataGrid
        style={{ height: '100%' }}
        columns={enhancedColumns}
        rows={filteredRows}
        pagination
        pageSizeOptions={[5, 10, 25, 100]}
        //autoHeight
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        disableRowSelectionOnClick
        slots={{
          toolbar: GridToolbarContainer,
        }}
        slotProps={{
          toolbar: {
            children: <GridToolbarExport printOptions={{ disableToolbarButton: true }} />,
          },
        }}
      />
    </Box>
  )
}

export default DataGridWithSearchAndExport
